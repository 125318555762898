'use client'

import { InfoCircle16, X24 } from '@vinted/monochrome-icons'
import {
  Button,
  Cell,
  Dialog,
  Divider,
  Icon,
  Image,
  Navigation,
  Spacer,
  Text,
} from '@vinted/web-ui'
import { ReactNode } from 'react'
import { useIntl } from 'react-intl'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { ContentLoader, ScrollableArea, useAsset } from '@marketplace-web/shared/ui-helpers'

import { formatCurrencyAmount } from '_libs/utils/formatString'
import { ClosetPromotionPricingModel } from 'types/models/closet-promotion'

type Props = {
  pricing: ClosetPromotionPricingModel | undefined
  isPricingLoading: boolean
  isOrderLoading: boolean
  show: boolean
  onNextAction: () => void
  onPreview: () => void
  onBack: () => void
  onDynamicPricingInfo: () => void
}

const ClosetPromotionPreCheckoutModal = ({
  pricing,
  isPricingLoading,
  isOrderLoading,
  show,
  onNextAction,
  onPreview,
  onBack,
  onDynamicPricingInfo,
}: Props) => {
  const translate = useTranslate('closet_promotion.pre_checkout_modal')
  const a11yTranslate = useTranslate('closet_promotion.a11y.actions')
  const { locale } = useIntl()

  const asset = useAsset('/assets/closet-promotion')

  function renderNavigation() {
    return (
      <Navigation
        right={
          <Button
            iconName={X24}
            theme="amplified"
            styling={Button.Styling.Flat}
            testId="closet-promotion-pre-checkout-back-button"
            onClick={onBack}
            aria={{ 'aria-label': a11yTranslate('close') }}
          />
        }
        body={
          <Text
            as="h2"
            type={Text.Type.Title}
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
        }
      />
    )
  }

  function renderIntro() {
    const values = {
      'learn-more': (chunks: Array<ReactNode>) => (
        <button onClick={onPreview} type="button" key="learn-more-button">
          <Text as="span" theme="primary" text={chunks} underline />
        </button>
      ),
    }

    return (
      <>
        <Cell>
          <Text as="h3" text={translate('intro.title')} type={Text.Type.Heading} />
        </Cell>
        <Cell
          title={<Text as="h4" text={translate('intro.visibility.title')} type={Text.Type.Title} />}
          body={translate('intro.visibility.body')}
          prefix={
            <Image
              role="img"
              src={asset('avatars-group.svg', { theme: { dark: 'dark/avatars-group.svg' } })}
              size={Image.Size.Large}
              alt=""
            />
          }
        />
        <Cell
          title={<Text as="h4" text={translate('intro.relevancy.title')} type={Text.Type.Title} />}
          body={translate('intro.relevancy.body', values)}
          prefix={
            <Image
              role="img"
              src={asset('hand-ok.svg', { theme: { dark: 'dark/hand-ok.svg' } })}
              size={Image.Size.Large}
              alt=""
            />
          }
        />
        <Cell
          title={<Text as="h4" text={translate('intro.insights.title')} type={Text.Type.Title} />}
          body={translate('intro.insights.body')}
          prefix={
            <Image
              role="img"
              src={asset('chart.svg', { theme: { dark: 'dark/chart.svg' } })}
              size={Image.Size.Large}
              alt=""
            />
          }
        />
      </>
    )
  }

  function renderPrice() {
    if (!pricing) return null

    const { totalPrice, discountedPrice, isSalesTaxApplicable } = pricing

    const formattedTotalPrice = formatCurrencyAmount(totalPrice, locale)
    const formattedDiscountedPrice = formatCurrencyAmount(discountedPrice, locale)

    const showTotalPrice = discountedPrice.amount < totalPrice.amount
    const suffix = (
      <div className="u-flexbox">
        <Icon name={InfoCircle16} color={Icon.Color.GreyscaleLevel2} />
      </div>
    )

    return (
      <>
        <Cell
          styling={Cell.Styling.Tight}
          suffix={suffix}
          onClick={onDynamicPricingInfo}
          clickable
          testId="closet-promotion-dynamic-pricing-info"
        >
          {showTotalPrice && <Text as="span" text={formattedTotalPrice} strikethrough />}
          <Spacer size={Spacer.Size.Small} orientation={Spacer.Orientation.Vertical} />
          <Text as="span" text={formattedDiscountedPrice} type={Text.Type.Title} />
        </Cell>
        {isSalesTaxApplicable && (
          <div>
            <Text
              as="span"
              theme="muted"
              text={translate('sales_tax_note')}
              testId="closet-promotion-pre-checkout-modal--sales-tax-note"
            />
          </div>
        )}
      </>
    )
  }

  function renderDeal() {
    if (!pricing) return null

    const { pricePerDay, effectiveDays } = pricing

    const formattedPricePerDay = formatCurrencyAmount(pricePerDay, locale)

    return (
      <Cell
        title={translate('duration_day_count', { count: effectiveDays }, { count: effectiveDays })}
        body={translate('price_per_day', { price: formattedPricePerDay })}
        suffix={renderPrice()}
      />
    )
  }

  const handleConfirmationClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    if (isOrderLoading) {
      event.preventDefault()

      return
    }

    onNextAction()
  }

  function renderConfirmationAction() {
    if (!pricing) return null

    return (
      <div className="u-ui-margin-horizontal-large u-ui-margin-bottom-large">
        <Button
          text={translate('actions.proceed')}
          styling={Button.Styling.Filled}
          onClick={handleConfirmationClick}
          isLoading={isOrderLoading}
          testId="closet-promotion-pre-checkout--proceed-button"
        />
      </div>
    )
  }

  function renderContent() {
    if (isPricingLoading || !pricing) {
      return <ContentLoader testId="pre-checkout-loader" styling={ContentLoader.Styling.Wide} />
    }

    return (
      <>
        <Image
          src={asset('closet-promotion-onboarding.png', {
            theme: { dark: 'dark/closet-promotion-onboarding.png' },
          })}
          alt=""
        />
        {renderIntro()}
      </>
    )
  }

  return (
    <Dialog show={show} hasScrollableContent testId="closet-promotion-pre-checkout-modal">
      <div className="u-fill-width">
        {renderNavigation()}
        <ScrollableArea>{renderContent()}</ScrollableArea>
        <Divider />
        {renderDeal()}
        {renderConfirmationAction()}
      </div>
    </Dialog>
  )
}

export default ClosetPromotionPreCheckoutModal

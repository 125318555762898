'use client'

import { useEffect, useState } from 'react'
import { Spacer } from '@vinted/web-ui'
import classNames from 'classnames'
import { QuickReplies24, StarLister24 } from '@vinted/monochrome-icons'

import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { useFetch } from '@marketplace-web/shared/api-client'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

import { clickEvent } from '_libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { getSellerBadges } from 'data/api'
import { transformSellerBadgesResponse } from 'data/api/transformers/response'
import { SellerBadgeDto } from 'types/dtos/seller-badge'

import {
  MAP_BADGE_TYPE_TO_BADGE_TARGET,
  ReplyTimeThreshold,
  SellerBadgeType,
} from '../../constants/badges'
import { getQuickRepliesProgress } from '../../utils/badges'
import BadgeBanner from './BadgeBanner'
import BadgeInfoModal from './BadgeInfoModal'

const ProfileBadges = () => {
  const translate = useTranslate('profile.badges')
  const translateA11y = useTranslate('user.seller_badges.a11y')
  const { track } = useTracking()
  const breakpoints = useBreakpoint()
  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')
  const { user } = useSession()

  const showProfileBadges = user && !user.business

  const fastReplierAbTest = useAbTest('fast_replier_badge_v3')

  const profileReworkAbTest = useAbTest('profile_wardrobe_web_rework')
  const isProfileReworkTestOn = profileReworkAbTest?.variant === 'on' && !breakpoints.portables

  const [selectedBadge, setSelectedBadge] = useState<SellerBadgeDto | null>(null)

  const { transformedData: badges, fetch: fetchBadges } = useFetch(
    getSellerBadges,
    transformSellerBadgesResponse,
  )

  const showMultipleBadges =
    isFastReplierBadgeEnabled && !!fastReplierAbTest && fastReplierAbTest.variant !== 'off'

  const classes = classNames({
    'u-flexbox': breakpoints.tabletsUp,
    'u-flex-direction-column': isProfileReworkTestOn,
  })

  useEffect(() => {
    if (!showProfileBadges) return

    fetchBadges()
  }, [fetchBadges, showProfileBadges])

  const handleOnClick = (badge: SellerBadgeDto) => () => {
    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: MAP_BADGE_TYPE_TO_BADGE_TARGET[badge.type],
      }),
    )
    setSelectedBadge(badge)
  }

  const getTitleTranslation = (badge: SellerBadgeDto) => {
    const { progress } = badge

    switch (badge.type) {
      case 'ACTIVE_LISTER': {
        if (progress.current === 0) return translate('frequent_uploads.title')
        if (progress.current < progress.goal)
          return translate(
            'frequent_uploads.title_progress',
            { items_count: progress.current },
            { count: progress.current },
          )

        return translate('frequent_uploads.title_success')
      }

      case 'FAST_REPLIER': {
        if (progress.current === ReplyTimeThreshold.VerySlow)
          return translate('quick_replier.title')
        if (progress.current <= progress.goal) return translate('quick_replier.title_success')
        if (progress.current > progress.goal && progress.current <= ReplyTimeThreshold.Slow)
          return translate('quick_replier.title_progress', { number_hours: progress.current })

        return translate('quick_replier.title')
      }
      default:
        return ''
    }
  }

  const getBodyTranslation = (badge: SellerBadgeDto) => {
    const { progress } = badge

    switch (badge.type) {
      case 'ACTIVE_LISTER': {
        if (progress.current === 0)
          return translate('frequent_uploads.body', { items_count: progress.goal })
        if (progress.current < progress.goal) return translate('frequent_uploads.body_progress')

        return translate('frequent_uploads.body_success')
      }

      case 'FAST_REPLIER': {
        if (progress.current === ReplyTimeThreshold.VerySlow) return translate('quick_replier.body')
        if (progress.current <= progress.goal) return translate('quick_replier.body_success')
        if (progress.current > progress.goal && progress.current <= ReplyTimeThreshold.Slow)
          return translate('quick_replier.body_progress')

        return translate('quick_replier.body')
      }
      default:
        return ''
    }
  }

  const getIconProps = (badge: SellerBadgeDto) => {
    const { progress } = badge

    switch (badge.type) {
      case 'ACTIVE_LISTER': {
        return {
          icon: StarLister24,
          progress: progress.current / progress.goal,
        }
      }
      case 'FAST_REPLIER': {
        return {
          icon: QuickReplies24,
          progress: getQuickRepliesProgress(progress.current),
        }
      }
      default:
        return { icon: null, progress: 0 }
    }
  }

  function renderSeparator() {
    return breakpoints.phones || isProfileReworkTestOn ? (
      <Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.Medium} />
    ) : (
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
    )
  }

  const renderBadge = (badge: SellerBadgeDto | undefined) => {
    if (!badge) return null

    return (
      <BadgeBanner
        key={badge.type}
        titleText={getTitleTranslation(badge)}
        bodyText={getBodyTranslation(badge)}
        iconProps={getIconProps(badge)}
        onClick={handleOnClick(badge)}
      />
    )
  }

  const renderProfileBadges = () => {
    if (!badges?.length) return null

    return (
      <div className={classes} aria-label={translateA11y('group_title')}>
        <SeparatedList separator={renderSeparator()}>
          {badges.map(badge => renderBadge(badge))}
        </SeparatedList>
      </div>
    )
  }

  if (!badges?.length) return null

  const getActiveListerBadge = () =>
    badges.find(badge => badge.type === SellerBadgeType.ActiveLister)

  return (
    <>
      <Spacer size={Spacer.Size.Medium} />
      {showMultipleBadges ? renderProfileBadges() : renderBadge(getActiveListerBadge())}
      <BadgeInfoModal selectedBadge={selectedBadge} onClose={() => setSelectedBadge(null)} />
    </>
  )
}

export default ProfileBadges

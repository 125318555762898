import { useEffect } from 'react'

import { useFetch } from '@marketplace-web/shared/api-client'
import { getSellerInsightsPhotoRecommendations } from 'data/api'
import { transformGetSellerInsightsPhotoRecommendationsResponse } from 'data/api/transformers/response'

const useGetPhotoRecommendations = () => {
  const {
    fetch: fetchPhotoRecommendations,
    isLoading: isPhotoRecommendationsLoading,
    transformedData: photoRecommendations,
  } = useFetch(
    getSellerInsightsPhotoRecommendations,
    transformGetSellerInsightsPhotoRecommendationsResponse,
  )

  useEffect(() => {
    fetchPhotoRecommendations()
  }, [fetchPhotoRecommendations])

  return {
    photoRecommendations,
    isPhotoRecommendationsLoading,
  }
}

export default useGetPhotoRecommendations

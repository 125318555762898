import { useEffect } from 'react'

import { useFetch } from '@marketplace-web/shared/api-client'
import { getSellerInsights } from 'data/api'
import { transformGetSellerInsightsResponse } from 'data/api/transformers/response'

const useGetSellerInsights = () => {
  const {
    fetch: fetchSellerInsights,
    isLoading: isSellerInsightsLoading,
    transformedData: sellerInsights,
  } = useFetch(getSellerInsights, transformGetSellerInsightsResponse)

  useEffect(() => {
    fetchSellerInsights()
  }, [fetchSellerInsights])

  return {
    sellerInsights,
    isSellerInsightsLoading,
  }
}

export default useGetSellerInsights

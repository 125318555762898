'use client'

import { useState } from 'react'
import { Spacer } from '@vinted/web-ui'
import classNames from 'classnames'

import {
  ClosetPromotionCheckout,
  ClosetPromotionBanner,
  BumpBanner,
  VasEntryPoint,
  useVasEntryPointsContext,
  BumpGiftBanner,
} from '@marketplace-web/domain/vas'
import { ReferralsBanner } from '@marketplace-web/domain/banners'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { ProfileBadges } from '@marketplace-web/domain/seller-engagement-tools'
import { ProductItemModel } from 'types/models'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useAbTest } from '@marketplace-web/shared/ab-tests'

import ProfileTaxpayerBanner from 'pages/Profile/ProfileBanners/ProfileTaxpayerBanner'

type Props = {
  renderCountDownBanner: () => JSX.Element | null
  getSingleBumpableItem: () => ProductItemModel | undefined
}

const ProfileOwnerBanners = ({ renderCountDownBanner, getSingleBumpableItem }: Props) => {
  const breakpoints = useBreakpoint()

  const profileReworkAbTest = useAbTest('profile_wardrobe_web_rework')
  const isProfileReworkTestOn = profileReworkAbTest?.variant === 'on' && !breakpoints.portables

  const { vasEntryPoints } = useVasEntryPointsContext()

  const [isPrecheckoutOpen, setIsPrecheckoutOpen] = useState(false)

  function handlePrecheckoutOpen() {
    setIsPrecheckoutOpen(true)
  }

  function handlePrecheckoutClose() {
    setIsPrecheckoutOpen(false)
  }

  function renderSeparator() {
    return breakpoints.phones ? (
      <Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.Medium} />
    ) : (
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.XLarge} />
    )
  }

  const isPromotedClosetsCheckoutEntryPointAvailable = vasEntryPoints?.some(
    entryPoint => entryPoint.name === VasEntryPoint.ProfilePromotedClosets,
  )

  const promotedClosetsBanner = vasEntryPoints?.find(
    entryPoint =>
      entryPoint.name === VasEntryPoint.ProfilePromotedClosets ||
      entryPoint.name === VasEntryPoint.PromotedClosetsStats,
  )

  const bumpBanner = vasEntryPoints?.find(entryPoint => entryPoint.name === VasEntryPoint.PushUps)

  const bumpGiftBanner = vasEntryPoints?.find(
    entryPoint => entryPoint.name === VasEntryPoint.NewMemberBumpOnboarding,
  )

  const classes = classNames({ 'u-flexbox': breakpoints.tabletsUp })

  return (
    <>
      {(!isProfileReworkTestOn || breakpoints.phones) && <ProfileTaxpayerBanner />}
      <ReferralsBanner
        bottomSpacer={breakpoints.tabletsUp && <Spacer size={Spacer.Size.XLarge} />}
      />
      {renderCountDownBanner()}
      <BumpGiftBanner
        bumpGiftBanner={bumpGiftBanner}
        isOtherVasBannerVisible={!!bumpBanner || !!promotedClosetsBanner}
      />
      <div className={classes}>
        <SeparatedList separator={renderSeparator()}>
          {bumpBanner && (
            <BumpBanner bumpBanner={bumpBanner} getSingleBumpableItem={getSingleBumpableItem} />
          )}
          {promotedClosetsBanner && (
            <ClosetPromotionBanner
              promotedClosetsBanner={promotedClosetsBanner}
              handlePrecheckoutOpen={handlePrecheckoutOpen}
            />
          )}
        </SeparatedList>
      </div>
      {(!isProfileReworkTestOn || breakpoints.phones) && <ProfileBadges />}
      {isPromotedClosetsCheckoutEntryPointAvailable && (
        <ClosetPromotionCheckout
          isOpen={isPrecheckoutOpen}
          handlePrecheckoutClose={handlePrecheckoutClose}
        />
      )}
    </>
  )
}

export default ProfileOwnerBanners

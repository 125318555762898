'use client'

import { Button } from '@vinted/web-ui'

import { ProductItemModel } from 'types/models'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { getBumpableItemDataFromModel } from 'data/utils/bump'
import { Screen } from 'constants/tracking/screens'

import useStartBumpCheckout from '../../hooks/useStartBumpCheckout'

type Props = {
  item: ProductItemModel
}

const ProfileItemBumpButton = ({ item }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('item.actions')
  const { startCheckout } = useStartBumpCheckout()

  function handleOnClick() {
    startCheckout(getBumpableItemDataFromModel(item))

    track(
      clickEvent({
        target: ClickableElement.PushUpItem,
        targetDetails: `${item.id}`,
        screen: Screen.CurrentUserProfile,
      }),
    )
  }

  return (
    <Button
      disabled={!item.canPushUp}
      size={Button.Size.Small}
      text={translate('bump')}
      onClick={handleOnClick}
      testId="bump-button"
    />
  )
}

export default ProfileItemBumpButton

'use client'

import { useEffect, useState } from 'react'

import { useFetch } from '@marketplace-web/shared/api-client'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { FeedbackFormModal } from '@marketplace-web/shared/ui-helpers'
import { clickEvent, viewScreenEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { getItemUploadFeedbackRatings, sendItemUploadFeedback } from 'data/api'
import { transformFeedbackRatingsResponse } from 'data/api/transformers/response'
import { UpdateFeedbackRatingOptionsArgs } from 'types/api/args'

import usePromoAfterItemUploadContext from '../../../hooks/usePromoAfterItemUploadContext'

type Props = { onClose: () => void }

const ItemUploadFeedback = ({ onClose }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { uploadedItemId: feedbackItemId, setNextPromotion } = usePromoAfterItemUploadContext()
  const isFeedbackEnabled = useFeatureSwitch('item_upload_feedback_form')
  const { track } = useTracking()

  const {
    fetch: fetchItemUploadFeedback,
    error,
    transformedData: feedbackRatings,
  } = useFetch(getItemUploadFeedbackRatings, transformFeedbackRatingsResponse)

  useEffect(() => {
    fetchItemUploadFeedback()
  }, [fetchItemUploadFeedback, track, isFeedbackEnabled])

  useEffect(() => {
    if (error) {
      setNextPromotion()

      return
    }

    if (!feedbackRatings) return

    setIsModalOpen(true)
    track(viewScreenEvent({ screen: Screen.ItemUploadFeedbackForm }))
  }, [feedbackRatings, error, track, setNextPromotion])

  const handleModalClose = (finalized = false) => {
    setIsModalOpen(false)
    setNextPromotion()

    if (finalized) return

    track(
      clickEvent({
        screen: Screen.ItemUploadFeedbackForm,
        target: ClickableElement.CloseScreen,
      }),
    )
  }

  const updateFeedbackOptions = async ({
    finalized,
    rating,
    message,
    feedbackStatementIds,
  }: UpdateFeedbackRatingOptionsArgs) => {
    if (!finalized) return

    await sendItemUploadFeedback({
      itemId: feedbackItemId,
      rating,
      finalized,
      comment: message || '',
      feedbackStatementIds,
    })
    handleModalClose(true)
    onClose()
  }

  return (
    <FeedbackFormModal
      isOpen={isModalOpen}
      ratings={feedbackRatings}
      isInputWithoutRatingOptions
      isCloseActionVisible={false}
      onClose={handleModalClose}
      onSubmit={updateFeedbackOptions}
    />
  )
}

export default ItemUploadFeedback

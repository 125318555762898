'use client'

import { navigateToPage } from '@marketplace-web/shared/browser'

import { useTracking } from '@marketplace-web/shared/event-tracker'

import { CLOSET_PROMOTION_STATS_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from '_libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'

import { VasEntryPointModel } from 'types/models'

import VasBanner from '../VasBanner/VasBanner'
import { VasEntryPoint, VasImages } from '../../constants/vas-entry-point'

type Props = {
  promotedClosetsBanner?: VasEntryPointModel
  handlePrecheckoutOpen: () => void
}

const ClosetPromotionBanner = ({ promotedClosetsBanner, handlePrecheckoutOpen }: Props) => {
  const { track } = useTracking()

  if (!promotedClosetsBanner) return null

  const isClosetPromoted = promotedClosetsBanner.name === VasEntryPoint.PromotedClosetsStats

  const handleClosetPromotionBannerClick = () => {
    if (isClosetPromoted) navigateToPage(CLOSET_PROMOTION_STATS_URL)

    if (!isClosetPromoted) {
      handlePrecheckoutOpen()

      track(
        clickEvent({
          screen: Screen.CurrentUserProfile,
          target: ClickableElement.PromoteCloset,
        }),
      )
    }
  }

  const bannerProps = {
    ...promotedClosetsBanner,
    onClick: handleClosetPromotionBannerClick,
    testId: isClosetPromoted ? 'cp-stats-banner' : 'cp-banner',
    icon: isClosetPromoted ? VasImages.VasClosetPromoStats : VasImages.VasClosetPromo,
  }

  return <VasBanner {...bannerProps} />
}

export default ClosetPromotionBanner

'use client'

import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { Button, Card, Cell, Divider, Icon, Spacer, Text } from '@vinted/web-ui'
import { InfoCircle24, Money24 } from '@vinted/monochrome-icons'

import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { FormattedMessage, useTranslate } from '@marketplace-web/shared/i18n'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'

import { formatCurrencyAmount } from '_libs/utils/formatString'
import { clickEvent, viewEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'

import { type SellerInsightsType } from '../../../constants/insights'
import SellerInsightsValuePropositionModal from '../SellerInsightsValuePropositionModal/SellerInsightsValuePropositionModal'

type Props = {
  sellerInsights: SellerInsightsType
}

const SellerInsightsBlocks = ({ sellerInsights }: Props) => {
  const { track } = useTracking()
  const { locale } = useIntl()
  const translate = useTranslate('profile.seller_insights')
  const breakpoints = useBreakpoint()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const activeItemsPrice = sellerInsights?.activeItemsStats.total
  const soldItemsPrice = sellerInsights?.soldItemsStats.total
  const recentItemsPrice = sellerInsights?.soldItemsStats.period.total
  const recentItemsPeriod = sellerInsights?.soldItemsStats.period.duration

  const sellerInsightsTipsBannerTest = useAbTest('seller_tip_banner_electronics')
  const isSellerInsightsTipsBannerEnabled = sellerInsightsTipsBannerTest?.variant === 'on'

  const profileReworkAbTest = useAbTest('profile_wardrobe_web_rework')
  const isProfileReworkTestOn = profileReworkAbTest?.variant === 'on' && !breakpoints.portables

  const isHorizontallyStacked = isSellerInsightsTipsBannerEnabled || isProfileReworkTestOn

  const horizontallyStackedClasses = classNames({
    'seller-insights-atom u-padding-large': true,
    'seller-insights-block-without-tips':
      isProfileReworkTestOn && !isSellerInsightsTipsBannerEnabled,
  })

  useEffect(() => {
    track(
      viewEvent({
        target: ViewableElement.SellerInsightsBlocks,
        screen: Screen.CurrentUserProfile,
      }),
    )
  }, [track])

  const handleOpenModal = () => {
    track(
      clickEvent({
        target: ClickableElement.SellerInsightsInfo,
        screen: Screen.CurrentUserProfile,
      }),
    )
    setIsModalOpen(true)
  }

  const hasRecentEarningsTotal = parseFloat(recentItemsPrice?.amount) !== 0

  const handleCloseModal = () => setIsModalOpen(false)

  const renderHeader = () => (
    <Cell
      prefix={
        <div className="u-flexbox u-align-items-center u-fill-height">
          <Icon name={Money24} color={Icon.Color.GreyscaleLevel2} />
        </div>
      }
      title={translate('title_secondary')}
      suffix={
        <Button
          inline
          iconName={InfoCircle24}
          size={Button.Size.Small}
          styling={Button.Styling.Flat}
          iconColor={Icon.Color.GreyscaleLevel2}
          onClick={handleOpenModal}
          testId="seller-insights-info"
          aria={{ 'aria-label': translate('link') }}
        />
      }
      styling={Cell.Styling.Tight}
      testId="seller-insights-header-v2"
    />
  )

  const renderSellerInsightsV1Body = () => (
    <>
      {renderHeader()}
      <Spacer size={Spacer.Size.Medium} />
      <Card>
        <Cell testId="seller-insights-blocks" styling={Cell.Styling.Tight}>
          <div className="u-flexbox u-flex-direction-row u-padding-medium">
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
              <Text
                text={translate('blocks.wardrobe_value.title')}
                type={Text.Type.Caption}
                as="h3"
              />
              <Text
                text={activeItemsPrice ? formatCurrencyAmount(activeItemsPrice, locale) : ''}
                type={Text.Type.Title}
                as="h2"
                testId="seller-insights-wardrobe-value"
              />
              <Text type={Text.Type.Caption} as="h3">
                <FormattedMessage
                  pluralize
                  count={sellerInsights?.activeItemsStats.count}
                  id="profile.seller_insights.blocks.wardrobe_value.subtitle"
                  values={{ wardrobe_items_count: sellerInsights?.activeItemsStats.count }}
                />
              </Text>
            </section>
            <Divider orientation={Divider.Orientation.Vertical} />
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
              <Text
                text={translate('blocks.earnings.experimental_title')}
                type={Text.Type.Caption}
                as="h3"
              />
              <Text
                text={soldItemsPrice ? formatCurrencyAmount(soldItemsPrice, locale) : ''}
                type={Text.Type.Title}
                as="h2"
                testId="seller-insights-earnings"
              />
              <Text
                type={Text.Type.Caption}
                theme={hasRecentEarningsTotal ? 'success' : 'muted'}
                as="h3"
              >
                {translate('blocks.earnings.subtitle', {
                  total_earned: recentItemsPrice
                    ? formatCurrencyAmount(recentItemsPrice, locale)
                    : '',
                })}
              </Text>
            </section>
          </div>
        </Cell>
      </Card>
    </>
  )

  const renderSellerInsightsWithTipsBannerBody = () => (
    <Card>
      <div className={horizontallyStackedClasses}>
        <Cell
          prefix={
            <Icon
              name={Money24}
              color={Icon.Color.GreyscaleLevel2}
              aria={{ 'aria-hidden': true }}
            />
          }
          title={translate('title_secondary')}
          body={translate('subtitle')}
          suffix={
            <Button
              inline
              iconName={InfoCircle24}
              size={Button.Size.Small}
              styling={Button.Styling.Flat}
              iconColor={Icon.Color.GreyscaleLevel2}
              onClick={handleOpenModal}
              testId="seller-insights-info"
              aria={{ 'aria-label': translate('link') }}
            />
          }
          styling={Cell.Styling.Tight}
        />
        <Cell testId="seller-insights-blocks" styling={Cell.Styling.Tight}>
          <div className="u-flexbox u-flex-direction-row u-padding-medium">
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width u-padding-right-medium">
              <Text
                alignment={Text.Alignment.Center}
                text={translate('blocks.wardrobe_value.title')}
                type={Text.Type.Caption}
                as="h3"
              />
              <Text
                text={activeItemsPrice ? formatCurrencyAmount(activeItemsPrice, locale) : ''}
                type={Text.Type.Title}
                as="h2"
                testId="seller-insights-wardrobe-value"
              />
              <Text alignment={Text.Alignment.Center} type={Text.Type.Caption} as="h3">
                <FormattedMessage
                  pluralize
                  count={sellerInsights?.activeItemsStats.count}
                  id="profile.seller_insights.blocks.wardrobe_value.subtitle"
                  values={{ wardrobe_items_count: sellerInsights?.activeItemsStats.count }}
                />
              </Text>
            </section>
            <Divider orientation={Divider.Orientation.Vertical} />
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width u-padding-left-medium">
              <Text
                alignment={Text.Alignment.Center}
                text={translate('blocks.earnings.experimental_title')}
                type={Text.Type.Caption}
                as="h3"
              />
              <Text
                text={soldItemsPrice ? formatCurrencyAmount(soldItemsPrice, locale) : ''}
                type={Text.Type.Title}
                as="h2"
                testId="seller-insights-earnings"
              />
              <Text
                alignment={Text.Alignment.Center}
                type={Text.Type.Caption}
                theme={hasRecentEarningsTotal ? 'success' : 'muted'}
                as="h3"
              >
                {translate('blocks.earnings.subtitle', {
                  total_earned: recentItemsPrice
                    ? formatCurrencyAmount(recentItemsPrice, locale)
                    : '',
                })}
              </Text>
            </section>
          </div>
        </Cell>
      </div>
    </Card>
  )

  return (
    <div className="u-flexbox u-flex-direction-column u-flex-grow u-flex-1">
      <Spacer size={Spacer.Size.XLarge} />
      {isHorizontallyStacked
        ? renderSellerInsightsWithTipsBannerBody()
        : renderSellerInsightsV1Body()}
      <SellerInsightsValuePropositionModal
        show={isModalOpen}
        onCloseOrConfirm={handleCloseModal}
        period={recentItemsPeriod}
      />
    </div>
  )
}

export default SellerInsightsBlocks

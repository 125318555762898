'use client'

import { createContext } from 'react'

import { VasEntryPointModel } from 'types/models'

export type VasEntryPointsContextType = {
  vasEntryPoints?: Array<VasEntryPointModel>
}

export const initialValues: VasEntryPointsContextType = {
  vasEntryPoints: undefined,
}

const VasEntryPointsContext = createContext<VasEntryPointsContextType>(initialValues)

export default VasEntryPointsContext

'use client'

import { useCallback, useEffect, useState } from 'react'
import { Badge, Cell, Divider, Spacer, Tabs, Text } from '@vinted/web-ui'
import classNames from 'classnames'

import { useBanners } from '@marketplace-web/domain/banners'
import { FeedbackProvider, ProfileFeedback } from '@marketplace-web/domain/profile-feedback'
import {
  SellerInsights,
  useFetchSellerBadgesList,
  useGetSellerInsights,
  useSellerBadgeAbTest,
} from '@marketplace-web/domain/seller-engagement-tools'
import { TaxpayersSpecialVerificationSuccessModal } from '@marketplace-web/domain/taxpayers'
import {
  BumpCheckout,
  BumpCheckoutProvider,
  VasEntryPointsProvider,
} from '@marketplace-web/domain/vas'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { ShowAtBreakpoint, useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useLocation } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { FormattedMessage, useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { isValueInObject, toUrlQuery } from '@marketplace-web/shared/utils'

import { clickEvent, viewScreenEvent, viewUserEvent } from '_libs/common/event-tracker/events'
import InfoBanner from 'components/InfoBanner'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import { BusinessAccountBanner, PoliciesModal } from 'pages/Profile/BusinessAccount'
import { EprBanner } from 'pages/Profile/Epr'
import ProfileTaxpayerBanner from 'pages/Profile/ProfileBanners/ProfileTaxpayerBanner'
import ProfileListingRestrictionBanner from 'pages/Profile/ProfileBanners/ProfileListingRestrictionBanner'
import { UserDto } from '@marketplace-web/shared/user-data'

import PromoAfterItemUploadProvider from '../../containers/PromoAfterItemUploadProvider'
import useFetchCatalogs from '../../hooks/useFetchCatalogs'
import useFetchProfileUserInfo from '../../hooks/useFetchUserInfo'
import useWardrobeItems from '../../hooks/useWardrobeItems'
import InfoHeader from './InfoHeader'
import ProfileAdminSection from './ProfileAdminSection'
import ProfileBanners from './ProfileBanners'
import ProfileInfo from './ProfileInfo'
import PromosAfterItemUpload from './PromosAfterItemUpload'
import UserInfoPanel from './UserInfoPanel/UserInfoPanel'
import UserItems from './UserItems'

enum TabType {
  Closet = 'closet',
  Feedback = 'feedback',
  About = 'about',
  Insights = 'insights',
}

const TabClickableElements: Record<TabType, ClickableElement> = {
  closet: ClickableElement.ProfileTab,
  feedback: ClickableElement.FeedbackTab,
  about: ClickableElement.AboutTab,
  insights: ClickableElement.SellerInsightsTab,
}

type ActiveTabProps = {
  showActions: boolean
}

type Props = {
  user: UserDto
}

const isTabType = (tabType: unknown): tabType is TabType => isValueInObject(tabType, TabType)

const Profile = ({ user }: Props) => {
  const breakpoints = useBreakpoint()
  const { replaceHistoryState, relativeUrl, searchParams } = useLocation()
  const { track } = useTracking()
  const translate = useTranslate('common')

  const { user: currentUser } = useSession()

  const isKillswitchBannersEnabled = useFeatureSwitch('killswitch_banners_web')

  const {
    items,
    itemsCount,
    totalItemsCount,
    isLoading,
    sortBy,
    catalogId,
    selectedSellerFilter,
    fetchMoreItems,
    updateFilters,
  } = useWardrobeItems({ userId: user.id })
  const { userInfo } = useFetchProfileUserInfo({ userId: user.id })
  const { catalogs } = useFetchCatalogs({ userId: user.id })
  const { sellerBadgesList } = useFetchSellerBadgesList({ user })
  const { sellerInsights, isSellerInsightsLoading } = useGetSellerInsights()
  const { fetchBanners, banners } = useBanners()

  const defaultActiveTab = isTabType(searchParams.tab) ? searchParams.tab : TabType.Closet

  const isContentTranslationEnabled = useFeatureSwitch('content_translation_web')

  const holidayModeRedirectAbTest = useAbTest('holiday_mode_redirect')

  useSellerBadgeAbTest({
    abTestName: 'fast_replier_badge_v3',
    user,
  })

  const profileReworkAbTest = useAbTest('profile_wardrobe_web_rework')
  const isProfileReworkTestOn = profileReworkAbTest?.variant === 'on' && !breakpoints.portables

  const [activeTabId, setActiveTabId] = useState(defaultActiveTab)
  const [isPoliciesModalOpen, setIsPoliciesModalOpen] = useState(!!searchParams.policy)
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false)

  const isViewingSelf = currentUser?.id === user.id
  const screenName = isViewingSelf ? Screen.CurrentUserProfile : Screen.UserProfile

  const isProfileReworkRendered = isProfileReworkTestOn && isViewingSelf

  const handleChangeTab = useCallback(
    (tabId: TabType) => {
      setActiveTabId(tabId)

      replaceHistoryState(`${relativeUrl}?${toUrlQuery({ tab: tabId })}`)
    },
    [relativeUrl, replaceHistoryState],
  )

  useTrackAbTest(
    holidayModeRedirectAbTest,
    // TODO: why we only track exposure for `on`?
    !!(holidayModeRedirectAbTest?.variant === 'on' && searchParams.on_holiday),
  )
  useTrackAbTest(profileReworkAbTest, isViewingSelf)

  useEffect(() => {
    if (isInitialFetchDone) return

    setIsInitialFetchDone(true)
    fetchMoreItems()
  }, [isInitialFetchDone, fetchMoreItems])

  useEffect(() => {
    if (!isViewingSelf) return
    if (isKillswitchBannersEnabled) return

    fetchBanners()
  }, [isKillswitchBannersEnabled, isViewingSelf, fetchBanners])

  useEffect(() => {
    const refererPath = document.referrer ? new URL(document.referrer).pathname : 'unknown'

    track(viewUserEvent({ path: refererPath, user_id: user.id }))
  }, [track, user.id])

  useEffect(() => {
    track(
      viewScreenEvent({
        screen: screenName,
      }),
    )
  }, [screenName, track])

  useEffect(() => {
    if ([TabType.Closet, TabType.Feedback, TabType.Insights].includes(activeTabId)) return
    if (breakpoints.phones && activeTabId === TabType.About) return

    handleChangeTab(TabType.Closet)
  }, [activeTabId, breakpoints.phones, handleChangeTab])

  const onTabChange: ComponentProps<typeof Tabs<TabType>>['onClick'] = tab => {
    handleChangeTab(tab.id)

    const screen = isViewingSelf ? Screen.CurrentUserProfile : Screen.UserProfile
    track(clickEvent({ screen, target: TabClickableElements[tab.id] }))
  }

  const changeTab = (tabId: TabType) => () => {
    handleChangeTab(tabId)
  }

  const togglePoliciesModal = () => {
    setIsPoliciesModalOpen(prevState => !prevState)
  }

  const getTabs = () => {
    const tabs = [
      { id: TabType.Closet, title: <FormattedMessage id="profile.tabs.closet" /> },
      { id: TabType.Feedback, title: <FormattedMessage id="profile.tabs.feedback" /> },
    ] as ComponentProps<typeof Tabs<TabType>>['items']

    const isInsightsTabVisible = sellerInsights?.isVisible && isViewingSelf

    if (isInsightsTabVisible) {
      tabs.push({
        id: TabType.Insights,
        children: (
          <div className="u-flexbox u-gap-medium u-align-items-center">
            <Text
              as="h3"
              // Manually handle color of text due to loss of this capacity using children prop
              theme={activeTabId === TabType.Insights ? 'amplified' : 'muted'}
              type={Text.Type.Subtitle}
            >
              <FormattedMessage id="profile.seller_insights.wardrobe_tab" />
            </Text>
            <Badge content={translate('new_badge')} theme="primary" />
          </div>
        ),
      })
    }

    if (breakpoints.phones) {
      tabs.push({ id: TabType.About, title: <FormattedMessage id="profile.tabs.about" /> })
    }

    return tabs
  }

  const renderWarningBlock = (keyId: string) => (
    <>
      <Cell body={<FormattedMessage id={keyId} />} theme="warning" />
      <Spacer size={Spacer.Size.Large} />
    </>
  )

  const renderItemsWarning = () => {
    const isViewedByGod = currentUser?.is_god
    const isViewedByModerator = currentUser?.moderator

    if (!(isViewingSelf || isViewedByGod || isViewedByModerator)) return null

    if (user.has_replica_proof_items) {
      return renderWarningBlock('profile.notification.replica_warning')
    }

    if (!user.has_item_alerts) return null

    return renderWarningBlock('profile.notification.items_warning')
  }

  const getSingleBumpableItem = () => {
    return items.find(item => item.canPushUp)
  }

  const mainAreaClasses = classNames({
    'u-flexbox u-flex-direction-column u-flex-1': isProfileReworkTestOn,
  })

  const renderClosetTab = (activeTabProps: ActiveTabProps) => {
    const { showActions } = activeTabProps

    if (breakpoints.tabletsUp) {
      return (
        <BumpCheckoutProvider>
          <div className={classNames('profile__items-wrapper')}>
            <div>
              <InfoBanner screen={screenName} theme="inverseExperimental" />
              <Spacer size={Spacer.Size.Medium} />
              <ProfileBanners
                user={user}
                showActions={showActions}
                viewingSelf={isViewingSelf}
                totalItemsCount={totalItemsCount}
                userId={user.id}
                getSingleBumpableItem={getSingleBumpableItem}
              />
              <BumpCheckout />
            </div>
            <UserItems
              userId={user.id}
              allowBump
              userOnHoliday={!!user?.is_on_holiday}
              currentUserId={currentUser?.id}
              items={items}
              itemsCount={itemsCount}
              isLoading={isLoading}
              selectedSellerFilter={selectedSellerFilter}
              sortBy={sortBy}
              catalogId={catalogId}
              catalogs={catalogs}
              fetchMoreItems={fetchMoreItems}
              updateFilters={updateFilters}
              totalItemsCount={totalItemsCount}
            />
          </div>
        </BumpCheckoutProvider>
      )
    }

    return (
      <BumpCheckoutProvider>
        <div className="u-ui-margin-large">
          <InfoBanner screen={screenName} theme="inverseExperimental" />
        </div>
        <div>
          {renderItemsWarning()}
          <SeparatedList separator={<Divider />}>
            {banners.businessAccount && isViewingSelf && (
              <BusinessAccountBanner banner={banners.businessAccount} />
            )}
            {banners.businessAccountEpr && isViewingSelf && (
              <EprBanner banner={banners.businessAccountEpr} />
            )}
            {user && (
              <ProfileInfo
                user={user}
                sellerBadgesList={sellerBadgesList}
                {...userInfo}
                showActions={showActions}
                viewingSelf={isViewingSelf}
                onTogglePoliciesModal={togglePoliciesModal}
                screen={Screen.UserProfile}
                compactMode
                onUserInfoClick={changeTab(TabType.Feedback)}
                isContentTranslationEnabled={isContentTranslationEnabled}
              />
            )}
            <>
              <Spacer size={Spacer.Size.Medium} />
              <ProfileBanners
                user={user}
                showActions={showActions}
                viewingSelf={isViewingSelf}
                totalItemsCount={totalItemsCount}
                userId={user.id}
                getSingleBumpableItem={getSingleBumpableItem}
              />
            </>
          </SeparatedList>
          <BumpCheckout />
        </div>
        <UserItems
          userId={user.id}
          allowBump
          userOnHoliday={!!user?.is_on_holiday}
          currentUserId={currentUser?.id}
          items={items}
          itemsCount={itemsCount}
          isLoading={isLoading}
          selectedSellerFilter={selectedSellerFilter}
          sortBy={sortBy}
          catalogId={catalogId}
          catalogs={catalogs}
          fetchMoreItems={fetchMoreItems}
          updateFilters={updateFilters}
          totalItemsCount={totalItemsCount}
        />
      </BumpCheckoutProvider>
    )
  }

  const renderAboutTab = (activeTabProps: ActiveTabProps) => {
    const { showActions } = activeTabProps

    return (
      <ProfileInfo
        user={user}
        sellerBadgesList={sellerBadgesList}
        {...userInfo}
        showActions={showActions}
        viewingSelf={isViewingSelf}
        onTogglePoliciesModal={togglePoliciesModal}
        screen={Screen.UserAbout}
        onUserInfoClick={changeTab(TabType.Feedback)}
        isContentTranslationEnabled={isContentTranslationEnabled}
      />
    )
  }

  const renderFeedbackTab = () => {
    const { feedbackProps } = userInfo

    return (
      <div className={classNames('profile__items-wrapper')}>
        <FeedbackProvider>
          <ProfileFeedback
            {...feedbackProps}
            isContentTranslationEnabled={isContentTranslationEnabled}
            viewingSelf={isViewingSelf}
            userId={user.id}
            username={user.login}
            showTitle={false}
          />
        </FeedbackProvider>
      </div>
    )
  }

  const renderInsightsTab = () => {
    if (!sellerInsights) return null

    return (
      <SellerInsights
        sellerInsights={sellerInsights}
        isSellerInsightsLoading={isSellerInsightsLoading}
      />
    )
  }

  const renderActiveTab = (activeTabProps: ActiveTabProps) => {
    switch (activeTabId) {
      case TabType.Closet:
        return renderClosetTab(activeTabProps)
      case TabType.Feedback:
        return renderFeedbackTab()
      case TabType.About:
        return renderAboutTab(activeTabProps)
      case TabType.Insights:
        return renderInsightsTab()
      default:
        return null
    }
  }

  const renderPoliciesModal = () => {
    if (!user?.business) return null

    return <PoliciesModal show={isPoliciesModalOpen} onClose={togglePoliciesModal} user={user} />
  }

  const { showSupportFunctionality } = userInfo

  const showActions = !(user?.is_hated || user?.hates_you) && !!user?.allow_direct_messaging

  return (
    <PromoAfterItemUploadProvider>
      <VasEntryPointsProvider userId={user.id}>
        {user && showSupportFunctionality && <ProfileAdminSection userId={user.id} />}
        {isViewingSelf ? (
          <ProfileListingRestrictionBanner
            listingRestrictedUntil={currentUser?.listing_restricted_until}
          />
        ) : null}
        <ShowAtBreakpoint breakpoint="phones">
          <Tabs
            items={getTabs()}
            width={Tabs.Width.Parent}
            activeItemId={activeTabId}
            onClick={onTabChange}
          />
          <Divider />
          {renderActiveTab({ showActions })}
        </ShowAtBreakpoint>
        <ShowAtBreakpoint breakpoint="tabletsUp">
          <div className="container">
            {isProfileReworkTestOn && <ProfileTaxpayerBanner />}
            {banners.businessAccountEpr && isViewingSelf && isProfileReworkTestOn && (
              <>
                <EprBanner banner={banners.businessAccountEpr} />
                <Spacer />
              </>
            )}
            <div
              className={`profile u-flex-direction-${isProfileReworkRendered ? 'row' : 'column'}`}
            >
              <div>
                {renderItemsWarning()}
                {banners.businessAccount && isViewingSelf && (
                  <>
                    <BusinessAccountBanner banner={banners.businessAccount} />
                    <Spacer size={Spacer.Size.X3Large} />
                  </>
                )}
                {banners.businessAccountEpr && isViewingSelf && !isProfileReworkTestOn && (
                  <>
                    <EprBanner banner={banners.businessAccountEpr} />
                    <Spacer size={Spacer.Size.X3Large} />
                  </>
                )}
                {user && !isProfileReworkRendered && (
                  <InfoHeader
                    user={user}
                    sellerBadgesList={sellerBadgesList}
                    {...userInfo}
                    showActions={showActions}
                    viewingSelf={isViewingSelf}
                    screen={screenName}
                    onTogglePoliciesModal={togglePoliciesModal}
                    onRatingClick={changeTab(TabType.Feedback)}
                    isContentTranslationEnabled={isContentTranslationEnabled}
                  />
                )}
                {user && isProfileReworkRendered && (
                  <UserInfoPanel
                    user={user}
                    {...userInfo}
                    screen={screenName}
                    onTogglePoliciesModal={togglePoliciesModal}
                    onRatingClick={changeTab(TabType.Feedback)}
                    isContentTranslationEnabled={isContentTranslationEnabled}
                  />
                )}
              </div>
              {isProfileReworkTestOn ? (
                <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.X3Large} />
              ) : (
                <Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.Large} />
              )}
              <div className={mainAreaClasses}>
                <span id="tabs">
                  <Tabs items={getTabs()} activeItemId={activeTabId} onClick={onTabChange} />
                </span>
                <Divider />
                {!isProfileReworkTestOn && <Spacer />}
                {renderActiveTab({ showActions })}
              </div>
            </div>
          </div>
        </ShowAtBreakpoint>
        <PromosAfterItemUpload />
        {renderPoliciesModal()}
        <TaxpayersSpecialVerificationSuccessModal />
      </VasEntryPointsProvider>
    </PromoAfterItemUploadProvider>
  )
}

export default Profile

'use client'

import { Button, Card, Dialog, Image, Text } from '@vinted/web-ui'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { navigateToPage } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useAsset } from '@marketplace-web/shared/ui-helpers'
import { ItemThumbnailSize } from '@marketplace-web/shared/utils'

import { clickEvent, itemUploadViewScreenEvent } from '_libs/common/event-tracker/events'
import { formatCurrencyAmount } from '_libs/utils/formatString'
import ItemBox from 'components/ItemBox'
import { ITEM_UPLOAD_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { getUploadAnotherItemTip } from 'data/api'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'
import { transformItemBoxDto } from 'data/transformers/item-box'
import { getItemThumbnail } from 'data/utils/item'

import { ItemBoxDto } from 'types/dtos'

type PartialItemBoxProps = Pick<ComponentProps<typeof ItemBox>, 'price' | 'image'> & {
  brand: string | null
  size: string | null
  itemBox?: ItemBoxDto | null
}

export type ListPromotionModalProps = {
  newItemId: null | number
  uploadSessionId: null | string
  showUploadAnotherItemTip?: boolean
  setNextPromotion: () => void
}

const ListPromotionModal = ({
  newItemId,
  uploadSessionId,
  showUploadAnotherItemTip,
  setNextPromotion,
}: ListPromotionModalProps) => {
  const translate = useTranslate()
  const { track } = useTracking()
  const { locale } = useIntl()

  const asset = useAsset('/assets/list-promotion')
  const isKillswitchEnabled = useFeatureSwitch('killswitch_list_another_item_promo_web')

  const [item, setItem] = useState<PartialItemBoxProps>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tipBody, setTipBody] = useState('')
  const [tipTitle, setTipTitle] = useState('')

  const fetchUploadAnotherItemTip = useCallback(
    async (itemId: number) => {
      if (!showUploadAnotherItemTip) {
        setNextPromotion()

        return
      }

      const response = await getUploadAnotherItemTip(itemId)

      if ('errors' in response) {
        setNextPromotion()

        return
      }

      setTipBody(response.body)
      setTipTitle(response.title)
      setIsModalOpen(true)
      setItem({
        ...response.item,
        price: formatCurrencyAmount(transformCurrencyAmountDto(response.item.price), locale),
        image: getItemThumbnail([response.item.photo], ItemThumbnailSize.Large),
        itemBox: response.item.item_box,
      })
    },
    [showUploadAnotherItemTip, locale, setNextPromotion],
  )

  useEffect(() => {
    if (!newItemId || isKillswitchEnabled) {
      setNextPromotion()

      return
    }

    fetchUploadAnotherItemTip(newItemId)
  }, [setNextPromotion, newItemId, isKillswitchEnabled, fetchUploadAnotherItemTip])

  useEffect(() => {
    if (!isModalOpen || !uploadSessionId) return

    track(
      itemUploadViewScreenEvent({
        screen: Screen.ListAnotherItem,
        uploadSessionId,
      }),
    )
  }, [isModalOpen, track, uploadSessionId])

  const trackClickEvent = (target: ClickableElement) => {
    track(clickEvent({ target }))
  }

  const onAcceptButtonClick = () => {
    trackClickEvent(ClickableElement.UploadMoreAccept)
    navigateToPage(ITEM_UPLOAD_URL)
  }

  const onSkipButtonClick = () => {
    trackClickEvent(ClickableElement.UploadMoreCancel)
    setIsModalOpen(false)
    setNextPromotion()
  }

  const renderItemBox = () => {
    if (!item) return null

    return (
      <div className="u-position-relative">
        <Image src={asset('background.jpg')} />
        <div className="list-promotion__item-wrapper">
          <Card styling={Card.Styling.Elevated}>
            <div className="list-promotion__item-box">
              <ItemBox
                image={item.image}
                price={item.price}
                url=""
                favourite={{ count: 0, favourited: false }}
                itemBox={item.itemBox && transformItemBoxDto(item.itemBox)}
              />
              <div className="list-promotion__overlay" />
            </div>
          </Card>
        </div>
      </div>
    )
  }

  return (
    <Dialog show={!!item && isModalOpen}>
      <div className="list-promotion">
        {renderItemBox()}
        <div className="c-modal__inline-content">
          <div className="c-modal__title">
            <Text as="h1" text={tipTitle} type={Text.Type.Heading} />
          </div>
          <div className="c-modal__body">
            <Text as="span" text={tipBody} />
          </div>
        </div>
        <div className="c-modal__actions">
          <div className="c-modal__action">
            <Button
              testId="list-promotion-submit-cta"
              text={translate('list_promotion.submit_cta')}
              onClick={onAcceptButtonClick}
              styling={Button.Styling.Filled}
              size={Button.Size.Medium}
            />
          </div>
          <div className="c-modal__action">
            <Button
              testId="list-promotion-cancel-cta"
              text={translate('list_promotion.cancel_cta')}
              onClick={onSkipButtonClick}
              styling={Button.Styling.Flat}
              size={Button.Size.Medium}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ListPromotionModal

import { getCurrentTimeInSeconds, MS_PER_SECOND } from '@marketplace-web/shared/utils'

import { CookieData } from '../types/cookie'
import { relativeDate } from '../utils/date'

export type CookieName = (typeof cookiesData)[number]['name']

const getMaxAge = (options: Parameters<typeof relativeDate>[0]) => {
  const currentTimeInSeconds = getCurrentTimeInSeconds()
  const relativeExpiryDate = relativeDate(options).getTime() / MS_PER_SECOND

  return Math.floor(relativeExpiryDate - currentTimeInSeconds)
}

const cookiesData = [
  // Recommended: maxAge should not be set to more than 1 year
  {
    name: 'seller_header_visits',
    maxAge: getMaxAge({ years: 1 }),
    httpOnly: false,
  },
  {
    name: 'refresh_token_web',
    maxAge: getMaxAge({ days: 7 }),
  },
  {
    name: 'access_token_web',
    // TODO: revisit `maxAge` requirement when 'vinted_fr_session' cookie is removed
    maxAge: getMaxAge({ days: 7 }),
  },
  {
    name: 'help_center_search_session_id',
    maxAge: getMaxAge({ days: 1 }),
  },
  {
    name: 'referrals_phones_bottom_sheet_was_shown',
    maxAge: getMaxAge({ years: 1 }),
  },
  { name: 'v_udt', maxAge: getMaxAge({ years: 20 }), httpOnly: true },
  {
    name: 'last_user_id',
    maxAge: getMaxAge({ years: 1 }),
  },
  {
    name: 'action_callback_route',
    maxAge: getMaxAge({ hours: 1 }),
  },
  {
    name: 'help_center_session_id',
    maxAge: getMaxAge({ minutes: 1 }),
  },
  {
    name: 'anon_id',
    maxAge: getMaxAge({ years: 20 }),
    sameSite: 'Lax',
  },
  {
    name: 'supports_webp',
    maxAge: getMaxAge({ years: 1 }),
  },
  {
    name: 'anonymous-locale',
    maxAge: getMaxAge({ years: 20 }),
  },
  {
    name: '_vinted_sb_fr_session',
    maxAge: getMaxAge({ days: 7 }),
  },
  {
    name: '_vinted_fr_session',
    maxAge: getMaxAge({ days: 7 }),
  },
  {
    name: 'locale',
  },
  {
    name: 'viewport_size',
    maxAge: getMaxAge({ days: 1 }),
  },
  {
    name: 'domain_selected',
    maxAge: getMaxAge({ years: 20 }),
  },
  {
    name: 'OptanonConsent',
    maxAge: getMaxAge({ years: 1 }),
  },
  {
    name: 'color_theme',
    maxAge: getMaxAge({ years: 1 }),
  },
  {
    name: 'anon_id',
    maxAge: getMaxAge({ years: 20 }),
    sameSite: 'Lax',
  },
  {
    name: 'X-VINTED-IN-APP',
  },
  {
    name: 'v_uid',
    maxAge: getMaxAge({ years: 20 }),
  },
  {
    name: 'app_banner',
    maxAge: getMaxAge({ days: 5 }),
  },
  {
    name: 'auth_redirect_retry_count',
    maxAge: 5,
  },
  {
    name: 'migration_code',
    maxAge: getMaxAge({ days: 1 }),
  },
  {
    name: 'banners_ui_state',
    maxAge: getMaxAge({ minutes: 10 }),
  },
  {
    name: 'seen_banners',
    maxAge: getMaxAge({ minutes: 10 }),
  },
  {
    name: 'last_nonce_token',
    maxAge: getMaxAge({ years: 1 }),
    httpOnly: true,
  },
] as const

const cookiesDataByName = cookiesData.reduce(
  (accumulator, cookie) => {
    accumulator[cookie.name] = cookie

    return accumulator
  },
  {} as Record<CookieName, CookieData>,
)

export { cookiesData, cookiesDataByName }
